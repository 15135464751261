import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

Vue.use(Vuex);

import RootState from "@/store/types";
import company from "@/store/company/index";

const store: StoreOptions<RootState> = {
    state: {
        cost: 0
    },
    mutations: {
        setCost(state, payload: number) {
            state.cost = payload;
        }
    },
    actions: {},
    modules: {
        company
    }
};

export default new Vuex.Store<RootState>(store);
