









































import { IFormBatteryDependency, TBrandType } from "@/components/form/types";
import { Component, VModel, Vue } from "vue-property-decorator";

@Component
export default class Form extends Vue {
  brands: TBrandType = [
    { name: "bosch", title: "Bosch" },
    { name: "yamaha", title: "Yamaha" },
    { name: "mahle", title: "Mahle" },
    { name: "brose", title: "Brose" },
    { name: "levo", title: "Levo" },
    { name: "other", title: "Other" }
  ];

  @VModel() battery!: IFormBatteryDependency;
}
