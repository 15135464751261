import { MutationTree } from "vuex";

import CompanyState from "@/store/company/types";

const mutations: MutationTree<CompanyState> = {
    setCompany(state, payload: CompanyState) {
        state.name = payload.name;
        state.street = payload.street;
        state.zip = payload.zip;
        state.phone = payload.phone;
    }
};

export default mutations;
