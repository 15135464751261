import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    title: "Evaluation <strong>Report</strong>",
    bikeCost: "Bike cost",
    bikeInformation: "Bike Information",
    statusInformation: "Status Information",
    purchasePrice: "Purchase price",
    evaluationDate: "Evaluation date",
    purchaseDate: "Purchase date",
    bikeAgeInMonths: "Bike age in months",
    placeOfBikePurchase: "Place of bike purchase",
    receiptAvailability: "Availability of a receipt",
    serviceReceipts: "All service receipts are available",
    bikeType: "Bike type",
    makeModel: "Make / Model",
    frameSize: "Frame size",
    frameNumber: "Frame Number",
    mileage: "Mileage",
    condition: {
      general: "General condition (cleanliness, rust, etc.)",
      chain: "Condition of chain and sprocket",
      tires: "Condition of tires",
      brakes: "Condition of the brake pads and brake discs",
      frame: "Condition of the frame and paint",
      bearings: "Condition of the bearings",
      wheels: "Condition of the wheels",
      attachments: "Condition of attachments",
      suspensionFork: "Function suspension fork",
      damper: "Function damper",
      battery: "Condition of battery",
      batteryDependency: {
        brand: "Brand",
        charges: "Battery charges",
        mileage: "Mileage"
      },
      serviceForLast12Months: "Service has been made in the past 12 months"
    }
  },
  de: {
    title: "Bewertungsprotokoll",
    bikeCost: "Marktwert des Fahrrades",
    bikeInformation: "Velo Informationen",
    statusInformation: "Zustand",
    purchasePrice: "Kaufpreis des Velos ",
    evaluationDate: "Datum Bewertung",
    purchaseDate: "Kaufdatum",
    bikeAgeInMonths: "Alter des Velo's in Monaten",
    placeOfBikePurchase: "Wo wurde das Fahrrad gekauft",
    receiptAvailability: "Ist eine Kaufquittung vorhanden",
    serviceReceipts: "sind sämtliche Servicebelege vorhanden",
    bikeType: "Fahrrad Typ",
    makeModel: "Marke / Modell",
    frameSize: "Rahmengrösse",
    frameNumber: "Rahmen-Nr",
    mileage: "Km-Stand",
    condition: {
      general: "Allgemeiner Zustand (Sauberkeit, Rost ect.)",
      chain: "Zustand von Kette und Ritzel",
      tires: "Zustand der Reifen",
      brakes: "Zustand der Bremsbeläge und Bremsscheiben/Felgen",
      frame: "Zustand Rahmen und Lack",
      bearings:
        "Zustand Lager (Radlager, Lenkkopflager, Tretlager oder Hinterbaulager)",
      wheels: "Zustand Laufräder",
      attachments: "Zustand Anbauteile",
      suspensionFork:
        "Funktion Federgabel, wann wurde der letzte Service gemacht",
      damper: "Funktion Dämpfer, wann wurde der letzte Service gemacht",
      battery: "Zustand Akku",
      batteryDependency: {
        brand: "Marke",
        charges: "Anzahl Ladungen",
        mileage: "Kilometer"
      },
      serviceForLast12Months:
        "wurde in den letzten 12 Monaten ein Service gemacht"
    }
  }
};

export default new VueI18n({
  locale: "de",
  messages
});
