































import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import { Mutation } from "vuex-class";

import moment from "moment";

import Evaluation from "@/evaluation";

import BikeInformation from "@/components/form/BikeInformation.vue";
import StatusInformation from "@/components/form/StatusInformation.vue";

import { IForm, TParameters, IEvaluation } from "@/components/form/types";

const DEFAULT_STATUS = 100;

@Component({
  components: {
    BikeInformation,
    StatusInformation
  }
})
export default class Form extends Vue {
  @Mutation("setCost") setCost!: (cost: number) => void;

  @Ref("form") readonly $form!: HTMLFormElement;

  parameters: TParameters = null;
  evaluation: IEvaluation | null = null;

  form: IForm = {
    loading: false,
    valid: false,
    company: { name: "", street: "", zip: "", phone: "" },
    employee: 0,
    statusInfo: null,
    bikeInfo: {
      placeOfBikePurchase: "",
      receiptAvailability: false,
      purchasePrice: "",
      purchaseDate: "",
      evaluationDate: moment().format("YYYY-MM-DD"),
      serviceReceipts: false,
      bikeType: "",
      makeModel: "",
      frameSize: "",
      frameNumber: "",
      mileage: ""
    }
  };

  @Watch("form", { deep: true })
  onFormChanged(): void {
    this.calculate();
  }

  calculate(): void {
    if (+this.form.bikeInfo.purchasePrice > 0) {
      const price: number = parseFloat(this.form.bikeInfo.purchasePrice);
      const date: string = this.form.bikeInfo.purchaseDate;
      const now: moment.Moment = moment(this.form.bikeInfo.evaluationDate);

      if (this.evaluation) {
        this.setCost(this.evaluation.calculate(price, date, now));
      }
    } else {
      this.setCost(0);
    }
  }

  init(): void {
    this.axios.get("/api/parameters.json").then(response => {
      const parameters: TParameters = response.data;

      if (parameters) {
        parameters.forEach(parameter => {
          let value;

          if (!this.form.statusInfo) this.form.statusInfo = {};

          switch (true) {
            case parameter.isSlider:
              value = DEFAULT_STATUS;
              break;
            default:
              value = true;
          }

          this.$set(this.form.statusInfo, parameter.name, value);

          if ("battery" === parameter.name) {
            this.$set(this.form.statusInfo, "batteryDependency", {
              brand: "",
              charges: "",
              mileage: ""
            });
          }
        });

        this.parameters = parameters;
        this.evaluation = new Evaluation(this.parameters, this.form.statusInfo);
      }
    });
  }

  created(): void {
    this.init();
  }
}
