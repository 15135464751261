



































import { Vue, Component, VModel, Prop, Watch } from "vue-property-decorator";

import { TParameter } from "@/components/form/types";

const DEFAULT_STATUS_VALUE = 100;

@Component
export default class Form extends Vue {
  @Prop({ type: Object }) readonly parameter!: TParameter;

  @VModel() data!: number | boolean | null;

  checkbox = false;

  @Watch("checkbox")
  onCheckboxChanged(): void {
    this.$emit("change-condition-checkbox", this.checkbox);
    this.data = DEFAULT_STATUS_VALUE;
  }

  get color(): string {
    if (this.data !== null) {
      switch (true) {
        case this.data >= 80:
          return "primary";
        case this.data >= 30:
          return "orange";
        default:
          return "error";
      }
    }

    return "primary";
  }
}
