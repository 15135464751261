





















































































































import { Vue, Component, VModel, Watch, Ref } from "vue-property-decorator";
import {
  IFormBikeInfo,
  TBikeType,
  TFrameSize,
} from "@/components/form/types";
import moment from "moment";

@Component
export default class VeloInformation extends Vue {
  activePurchaseDatePicker: string | null = "";
  purchaseDateMenu = false;

  activeEvaluationDatePicker: string | null = "";
  evaluationDateMenu = false;

  bikeTypes: TBikeType = [
    { id: 1, name: "Kindervelo" },
    { id: 2, name: "Jugendvelo" },
    { id: 3, name: "MTB Race" },
    { id: 4, name: "MTB Trail" },
    { id: 5, name: "MTB Gravity" },
    { id: 6, name: "Rennvelo" },
    { id: 7, name: "E-MTB" },
    { id: 8, name: "E-Bike" },
    { id: 9, name: "E-Road" },
    { id: 10, name: "Velo" },
    { id: 11, name: "City Bike" },
  ];

  frameSizes: TFrameSize = [
    { id: 1, name: "xx-small" },
    { id: 2, name: "x-small" },
    { id: 3, name: "small" },
    { id: 4, name: "medium" },
    { id: 5, name: "large" },
    { id: 6, name: "x-large" },
    { id: 7, name: "xx-large" },
    { id: 8, name: "onesize" },
    { id: 9, name: '16" Kindervelo' },
    { id: 10, name: '20" Kindervelo' },
    { id: 11, name: '24" Kindervelo' },
    { id: 12, name: "44cm" },
    { id: 13, name: "48cm" },
    { id: 14, name: "49cm" },
    { id: 15, name: "50cm" },
    { id: 16, name: "51cm" },
    { id: 17, name: "52cm" },
    { id: 18, name: "53cm" },
    { id: 19, name: "54cm" },
    { id: 20, name: "55cm" },
    { id: 21, name: "56cm" },
    { id: 22, name: "58cm" },
    { id: 23, name: "61cm" },
    { id: 24, name: "64cm" },
  ];

  @Ref("purchaseDateMenu") readonly $purchaseDateMenu!: HTMLFormElement;
  @Ref("evaluationDateMenu") readonly $evaluationDateMenu!: HTMLFormElement;

  @VModel({ type: Object }) bikeInfo!: IFormBikeInfo;

  get bikeAgeInMonths(): string {
    const result: number = moment(this.bikeInfo.evaluationDate).diff(
      moment(this.bikeInfo.purchaseDate),
      "months"
    );

    if (!result) {
      return "0";
    }

    if (result < 0) {
      this.bikeInfo.purchaseDate = "";

      return "0";
    }

    return result.toString();
  }

  get maxEvaluationDate(): string {
    return moment().format("YYYY-MM-DD");
  }

  get maxPurchaseDate(): string {
    return moment(this.bikeInfo.evaluationDate).format("YYYY-MM-DD");
  }

  get evaluationFormattedDate(): string {
    return this.bikeInfo.evaluationDate
      ? moment(this.bikeInfo.evaluationDate).format("MMM YYYY")
      : "";
  }

  get purchaseFormattedDate(): string {
    return this.bikeInfo.purchaseDate
      ? moment(this.bikeInfo.purchaseDate).format("MMM YYYY")
      : "";
  }

  @Watch("evaluationDateMenu")
  onEvaluationDateMenuChanged(val: string | null): void {
    val && setTimeout(() => (this.activeEvaluationDatePicker = "YEAR"));
  }

  @Watch("purchaseDateMenu")
  onPurchaseDateMenuChanged(val: string | null): void {
    val && setTimeout(() => (this.activePurchaseDatePicker = "YEAR"));
  }

  savePurchaseDate(date: string | null): void {
    this.$purchaseDateMenu.save(date);
  }

  saveEvaluationDate(date: string | null): void {
    this.$evaluationDateMenu.save(date);
  }
}
