import Vue from "vue";
import VueCurrencyFilter from "vue-currency-filter";

Vue.use(VueCurrencyFilter, {
    symbol: "",
    thousandsSeparator: "'",
    fractionCount: 2,
    fractionSeparator: ".",
    symbolSpacing: false
});

Vue.filter("money", (value: string | number) => {
    if (!value) {
        return "CHF 0.-";
    }

    return `CHF ${Vue.filter("currency")(value).replace(/.00$/g, ".-")}`;
});
