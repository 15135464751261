import {
    TParameters,
    TParameter,
    TFormStatusInfo,
    IEvaluation
} from "@/components/form/types";

import moment from "moment";

class Evaluation implements IEvaluation {
    defaultVat = 8;
    defaultSalvage = 20;
    defaultLife = 10;
    defaultFactor = 1.5;

    parameters: TParameters;
    statusInfo: TFormStatusInfo;

    constructor(parameters: TParameters, statusInfo: TFormStatusInfo) {
        this.parameters = parameters;
        this.statusInfo = statusInfo;
    }

    public calculate(price = 0, date: string, now: moment.Moment): number {
        price = this.getPriceByDate(price, date, now);
        price = this.getPriceByStatus(price);

        return price;
    }

    protected getPriceByDate(
        price: number,
        date: string,
        now: moment.Moment
    ): number {
        const numberOfYears: number = now.diff(moment(date), "year") || 0;
        const numberOfMonths: number = now.diff(moment(date), "months") || 0;
        const restOfMonths: number = numberOfMonths % 12;

        let amountOfDeductions = 0;

        switch (true) {
            case numberOfMonths > 120:
                for (let year = 1; year <= 10; year++)
                    amountOfDeductions += this.DDB(price, year);
                break;
            case numberOfMonths < 7:
                amountOfDeductions = (this.DDB(price, 1) / 12) * 6;
                break;
            default:
                for (let year = 1; year <= numberOfYears; year++) {
                    amountOfDeductions += this.DDB(price, year);
                }

                if (restOfMonths > 0) {
                    amountOfDeductions +=
                        (this.DDB(price, numberOfYears + 1) / 12) *
                        restOfMonths;
                }
        }

        return price - amountOfDeductions;
    }

    protected getPriceByStatus(price: number): number {
        let paramsCost = 0;

        if (this.parameters) {
            this.parameters.forEach(parameter => {
                paramsCost +=
                    (price /
                        ((100 + this.defaultVat) / parameter.percent) /
                        100) *
                    this.getStatusVatValue(parameter);
            });
        }

        return price - paramsCost;
    }

    protected getStatusVatValue(parameter: TParameter): number {
        let result = 0;

        if (parameter.isSlider && null !== this.statusInfo[parameter.name]) {
            switch (this.statusInfo[parameter.name]) {
                case 80:
                    result = 20;
                    break;
                case 60:
                    result = 40;
                    break;
                case 40:
                    result = 60;
                    break;
                case 20:
                    result = 80;
                    break;
                case 0:
                    result = 100;
                    break;
            }
        } else if (!parameter.isSlider) {
            result = this.statusInfo[parameter.name] ? 0 : 30;
        }

        return result;
    }

    protected DDB(
        cost: number,
        period: number,
        salvage = this.defaultSalvage,
        life = this.defaultLife,
        factor = this.defaultFactor
    ): number {
        if (
            !isNaN(cost) &&
            !isNaN(period) &&
            !isNaN(salvage) &&
            !isNaN(life) &&
            !isNaN(factor)
        ) {
            if (
                cost <= 0 ||
                life <= 0 ||
                salvage / cost < 0 ||
                period < 1 ||
                factor <= 0.0 ||
                period > life
            ) {
                return NaN;
            }

            let depreciation = 0;
            let previousDepreciation = 0;

            for (let per = 1; per <= period; ++per) {
                depreciation = Math.min(
                    (cost - previousDepreciation) * (factor / life),
                    cost - salvage - previousDepreciation
                );

                previousDepreciation += depreciation;
            }

            return +depreciation.toFixed(2);
        }

        return 0;
    }
}

export default Evaluation;
