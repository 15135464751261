






import { Vue, Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

import Form from "@/components/Form.vue";

import CompanyState from "@/store/company/types";

@Component({
  name: "Home",
  components: {
    Form
  }
})
export default class Home extends Vue {
  @Getter("companyIsExist") companyIsExist!: boolean;
  @Getter("company") company!: CompanyState;
}
