import { Module } from "vuex";

import RootState from "@/store/types";
import CompanyState from "@/store/company/types";

import getters from "@/store/company/getters";
import mutations from "@/store/company/mutations";

const state: CompanyState = {
    name: "",
    street: "",
    zip: "",
    phone: ""
};

const company: Module<CompanyState, RootState> = { state, getters, mutations };

export default company;
