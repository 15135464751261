import { GetterTree } from "vuex";

import RootState from "@/store/types";
import CompanyState from "@/store/company/types";

const getters: GetterTree<CompanyState, RootState> = {
    companyIsExist(state): boolean {
        return !!(state.name && state.street && state.zip && state.phone);
    },
    company(state): CompanyState {
        return state;
    }
};

export default getters;
