


















import { Vue, Component, VModel, Prop } from "vue-property-decorator";

import { TParameters, TFormStatusInfo } from "@/components/form/types";

import Condition from "@/components/form/Condition.vue";
import BatteryDependency from "@/components/form/BatteryDependency.vue";

@Component({
  components: {
    Condition,
    BatteryDependency
  }
})
export default class StatusInformation extends Vue {
  @Prop({
    type: Array,
    default: () => {
      return [];
    }
  })
  readonly parameters!: TParameters;

  showBatteryDependency = false;

  @VModel({ type: Object }) statusInfo!: TFormStatusInfo;

  onChangeConditionCheckbox(event: boolean, parameterName: string): void {
    if ("battery" === parameterName) {
      this.showBatteryDependency = event;
    }
  }
}
