import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#8dbc2d",
                secondary: "#949bb0",
                accent: "#3f4a6f",
                error: "#e3342f",
                info: "#6cb2eb",
                success: "#8dbc2d",
                warning: "#e6f52f"
            }
        }
    }
});
