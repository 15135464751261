














































import { Vue, Component } from "vue-property-decorator";
import { State } from "vuex-class";

import "@/plugins/money";

@Component({
  name: "App",
  metaInfo: {
    title: "Evaluation Report",
  },
})
export default class App extends Vue {
  @State("cost") cost!: number;

  get langCodes(): Array<string> {
    return ["en", "de"];
  }
}
